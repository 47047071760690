'use strict';

/**
 * Log error.
 * @param {string} message - Message.
 */
function log(message) {
    if (window.console) {
        window.console.error(message);
    }
}

/**
 * Fill BOPIS data.
 * @param {HTMLElement} container - Container for header.
 * @param {string} storeName - Name of preferred store.
 */
function fillBopis(container, storeName) {
    if (!container) {
        return;
    }

    if (storeName && typeof storeName === 'string') {
        const selectedStoreContainer = container.querySelector('.js-selected-store');
        const selectedStoreName = container.querySelector('.js-store-name');

        if (selectedStoreName) {
            selectedStoreName.textContent = storeName;
        }

        if (selectedStoreContainer && selectedStoreName) {
            selectedStoreContainer.classList.remove('d-none');
            selectedStoreContainer.classList.add('d-inline-block');
        }
    } else {
        const selectStoreLabel = container.querySelector('.js-select-store-label');

        if (selectStoreLabel) {
            selectStoreLabel.classList.remove('d-none');
            selectStoreLabel.classList.add('d-inline-block');
        }
    }
}

/**
 * Set class and value.
 * @param {HTMLInputElement} field - Hidden field.
 * @param {string} className - Class name.
 * @param {string} value - Value.
 */
function setAccountHeaderField(field, className, value) {
    const input = field;
    if (input) {
        input.className = className;
        input.setAttribute('value', String(value));
    }
}

const handlers = {
    wishlist(data) {
        const status = data.wishlistItemStatus;

        if (!status || typeof status !== 'object') {
            return;
        }

        const container = document.querySelector('[data-uncached-handler="wishlist"]');

        if (!container) {
            return;
        }

        const query = status.itemExists ? '.added-to-wishlist' : '.add-to-wishlist';
        const element = container.querySelector(query);

        if (element) {
            element.classList.remove('d-none');
        }
    },

    minicart(data) {
        const cartQuantity = parseInt(data.cartQuantity, 10);

        // Early exit when data is incomplete or invalid.
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(cartQuantity) || cartQuantity < 0) {
            return;
        }

        // Early exit when container is not found.
        const container = document.querySelector('[data-uncached-handler="minicart"]');

        if (!container) {
            return;
        }

        // Fill elements that only show number of items.
        const quantityElements = container.querySelectorAll('.minicart-quantity');

        for (let i = 0; i < quantityElements.length; i += 1) {
            const element = quantityElements[i];
            element.textContent = String(cartQuantity);

            if (cartQuantity > 0 && cartQuantity < 100) {
                element.classList.remove('d-none');
            }
        }

        const titleTemplate = container.getAttribute('data-title');
        const title = titleTemplate ? titleTemplate.replace('%%', String(cartQuantity)) : null;

        if (!title) {
            return;
        }

        // Set attributes on link.
        const miniCartLink = container.querySelector('.minicart-link');

        if (miniCartLink) {
            miniCartLink.setAttribute('title', title);
            miniCartLink.setAttribute('aria-label', title);
        }

        // Set content of SVG title and desc.
        const svgElements = container.querySelectorAll('svg title, svg desc');

        for (let i = 0; i < svgElements.length; i += 1) {
            svgElements[i].textContent = title;
        }
    },

    bopis(data) {
        if (!data.bopis) {
            return;
        }

        const bopis = data.bopis;

        // BOPIS header is not rendered on cart page.
        if (bopis.isCartPage) {
            return;
        }

        const storeName = bopis.preferredStoreName || '';
        const desktopContainer = document.querySelector('[data-uncached-handler="bopis-desktop"]');
        const mobileContainer = document.querySelector('[data-uncached-handler="bopis-mobile"]');

        fillBopis(desktopContainer, storeName);
        fillBopis(mobileContainer, storeName);
    },

    accountHeader(data) {
        const { user } = data;

        if (!user) {
            return;
        }

        const container = document.querySelector('[data-uncached-handler="accountHeader"]');

        if (!container) {
            return;
        }

        const renderSelector = user.authenticated ? '[data-logged-in="true"]' : '[data-logged-in="false"]';
        const renderContainer = container.querySelector(renderSelector);

        if (!renderContainer) {
            return;
        }

        const nameElement = user.authenticated ? renderContainer.querySelector('[data-user-name]') : null;

        if (nameElement) {
            nameElement.textContent = user.name || '';
        }

        renderContainer.setAttribute('data-copy-from', 'account-header');
        container.parentNode.replaceChild(renderContainer, container);
    },

    accountHeaderFields(data) {
        const { user } = data;

        if (!user) {
            return;
        }

        const container = document.querySelector('[data-uncached-handler="accountHeaderFields"]');

        if (!container) {
            return;
        }

        const userLoggedInField = container.querySelector('[data-set-user-logged-in]');
        const wishlistValidField = container.querySelector('[data-set-wishlist-valid]');
        const authenticated = Boolean(user.authenticated);

        setAccountHeaderField(userLoggedInField, 'user-logged-in', authenticated);
        setAccountHeaderField(wishlistValidField, authenticated ? 'is-wishlist-valid' : '', authenticated);
    }

};

/**
 * Handle uncached data.
 */
function handleUncachedData() {
    const data = window.uncachedData || null;

    if (!data) {
        log('Global uncachedData not found');
        return;
    }

    for (let i = 0, keys = Object.keys(handlers); i < keys.length; i += 1) {
        const key = keys[i];
        const handler = handlers[key];

        try {
            handler(data);
        } catch (e) {
            log(`Error in uncached data handler ${key}: ` + e.message);
        }
    }
}

module.exports = handleUncachedData;
