'use strict';

// this file is copied from the base

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var debounce = require('../util/debounce');
const setFocus = require('../util/setFocus');
var globalNavItem = '.navbar-nav > li';
var dropDownArrowBtn = 'button.dropdown-toggle';

var clearSelection = function (element) {
    var $element = $(element);
    var $showDropDown = $element.closest('.dropdown.show');
    $element.closest('.dropdown').children('.dropdown-menu').children('.top-category')
        .detach();
    $showDropDown.find('.dropdown-item').removeClass('show');
    $showDropDown.children('.nav-link').attr('aria-expanded', 'false');
    $showDropDown.children('.dropdown-menu').attr('aria-hidden', 'true');
    $showDropDown.removeClass('show');
    $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
    $element.closest('li').detach();
    $('.logo-nb-menu').removeClass('invisible position-absolute');
};

// custom changes for sticky header height calculation
var headerHeight;
var $window = $(window);
var $headerParent = $('.header-container:not(.no-hide)');
var actionShoeFinder = $('.page').data('action');
var stickyHeader = function () {
    var $headerFixed = $('.header-fixed');
    headerHeight = $headerFixed.outerHeight();
    if (actionShoeFinder && (actionShoeFinder.indexOf('ShoeFinder') === 0)) {
        $headerParent.css({ 'min-height': 120, transition: '0.2s' });
    } else {
        $headerParent.css({ 'min-height': headerHeight, transition: '0.2s' });
    }
    $headerFixed.addClass('position-fixed');
    $('body').trigger('stickyHeaderUpdate');
};

var updateFlyoutClose = function () {
    $('.header .close-button').on('click', function () {
        $('body').removeClass('fixed');
    });
};

module.exports = function () {
    // sticky header should be calculated/initialized last thing to avoid miscalculations
    $(document).ready(function () {
        setTimeout(stickyHeader, 100);
    });

    var isDesktop = (function () {
        // Cache value to avoid forced reflows when function is called.
        let cachedValue = $('.menu-toggleable-left').css('position') !== 'fixed';

        window.addEventListener('resize', () => {
            cachedValue = $('.menu-toggleable-left').css('position') !== 'fixed';
        });

        return function () {
            return cachedValue;
        };
    }());

    var showHideGlobalNavigation = function () {
        if (isDesktop()) {
            var eventElement = this;
            $(globalNavItem).each(function () {
                if (!$.contains(this, eventElement)) {
                    var $this = $(this);
                    if ($this.hasClass('show')) {
                        $this.removeClass('show');
                        $this.children('.dropdown-menu').removeClass('show');
                        $this.find(dropDownArrowBtn).attr('aria-expanded', 'false');
                    }
                }
            });
            var $eventElement = $(this);
            $eventElement.parent().addClass('show');
            $eventElement.parent().find(dropDownArrowBtn).attr('aria-expanded', 'true');
            $eventElement.parent('.nav-item').find('ul').attr('aria-expanded', 'true');
            var $dropdownMenu = $eventElement.siblings('.dropdown-menu');
            // need to close all the dropdowns that are not direct parent of current dropdown
            if ($dropdownMenu.length) {
                $dropdownMenu.addClass('show');
            }
        }
    };

    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').on('click', function () {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link',
        {
            40: function (menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    $(this).parent().find(dropDownArrowBtn).attr('aria-expanded', 'true');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).parent().find(dropDownArrowBtn).attr('aria-expanded', 'false');
                    if (menuItem.next().length <= 0) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a') // set focus to the first menuitem
                            .first()
                            .focus();
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function (menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).parent().find(dropDownArrowBtn).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).parent().find(dropDownArrowBtn).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a')
                        .first()
                        .focus();
                }
            },
            38: function (menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find(dropDownArrowBtn).attr('aria-expanded', 'false');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .find(dropDownArrowBtn)
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children() // set the focus to the last menuItem
                        .first()
                        .focus();
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function (menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).parent().find(dropDownArrowBtn).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children()
                        .first()
                        .focus()
                        .attr('aria-expanded', 'false');
                }
            },
            27: function (menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').find(dropDownArrowBtn)
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        });
    $(globalNavItem).on('click', function () {
        if (!isDesktop()) {
            $(this).find('.menu-flyout ul li ul').addClass('dropdown-menu');
        }
    });
    $('.dropdown:not(.disabled) [data-toggle="dropdown"]')
        .on('click', function (e) {
            if (!isDesktop()) {
                $('.modal-background').css('display', 'block');
                // copy parent element into current UL
                var li = $('<span class="top-category p-0 d-inline-block text-center position-absolute" role="button"></span>');
                var link = $(this).clone().removeClass('dropdown-toggle')
                    .removeAttr('data-toggle')
                    .removeAttr('aria-expanded')
                    .removeClass('nav-link')
                    .removeClass('dropdown-link')
                    .attr('aria-haspopup', 'false');
                li.append(link);
                $('.logo-nb-menu').addClass('invisible position-absolute');
                var closeMenu = $('<li class="nav-menu"></li>');
                var $this = $(this);
                closeMenu.append($('.close-menu').first().clone());
                var $dropMenu = $this.parent().children('.dropdown-menu');
                $dropMenu.find('.nav-menu').remove();
                $dropMenu.prepend(closeMenu)
                    .attr('aria-hidden', 'false');
                var $close = $this.parent().children('.dropdown-menu').find('.close-menu');
                $close.find('.top-category').remove();
                $close.append(li);
                $('.language-icon').addClass('d-none');
                // copy navigation menu into view
                $this.parent().addClass('show');
                $this.attr('aria-expanded', 'true');
                $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                setTimeout(function () {
                    $(link).focus();
                    $('.navbar-toggleable-md')[0].scrollTo(0, 0);
                }, 100);
                e.preventDefault();
            }
        })
        .on('mouseenter', showHideGlobalNavigation)
        .parent()
        .on('mouseleave', function () {
            if (isDesktop()) {
                var $eventElement = $(this);
                $eventElement.removeClass('show');
                $eventElement.children('.dropdown-menu').removeClass('show');
                $eventElement.find(dropDownArrowBtn).attr('aria-expanded', 'false');
            }
        });

    $('.navbar-nav').on('click', '.back', function (e) {
        e.preventDefault();
        clearSelection(this);
        var $document = $(document);
        $document.find('.language-icon').removeClass('d-none');
        if ($document.find('.top-category').length > 0) {
            $document.find('.logo-nb-menu').addClass('invisible position-absolute');
        } else {
            $document.find('.logo-nb-menu').removeClass('invisible position-absolute');
        }
    });
    $('.dropdown:not(.disabled) button.dropdown-toggle').on('click', showHideGlobalNavigation);

    $('a.nav-link, button.dropdown-toggle', '.main-menu ul.navbar-nav .nav-item').on('focusin', function () {
        var eventElement = this;
        $(globalNavItem).each(function () {
            if (!$.contains(this, eventElement)) {
                var $this = $(this);
                if ($this.hasClass('show')) {
                    $this.removeClass('show');
                    $this.children('.dropdown-menu').removeClass('show');
                    $this.find(dropDownArrowBtn).attr('aria-expanded', 'false');
                }
            }
        });
    });
    $('.navbar-nav').on('click', '.close-button', function (e) {
        e.preventDefault();
        var $navBar = $('.navbar-nav');
        $navBar.find('.top-category').detach();
        $navBar.find('.nav-menu').detach();
        $navBar.find('.show').removeClass('show');
        $('.menu-toggleable-left').removeClass('in');

        $('.main-menu').siblings().attr('aria-hidden', 'false');
        $('header').siblings().attr('aria-hidden', 'false');

        $('.modal-background').hide();
    });

    $(document).on('click', '.navbar-toggler-js', function (e) {
        e.preventDefault();

        var isOpen = $('.navbar-toggler-js').hasClass('navbar-toggler--is-active');
        $('#hamburger-icon-custom').toggleClass('hamburger-icon--open');

        var $mainMenu = $('.main-menu');

        if (isOpen) {
            // Closing Menu
            $('body').removeClass('fixed');
            $('.navbar-toggler-js').removeClass('navbar-toggler--is-active');
            $('header').siblings().attr('aria-hidden', 'false');

            $('.logo-nb-menu').removeClass('invisible position-absolute');
            $mainMenu.attr('aria-hidden', 'true');
            $mainMenu.siblings().attr('aria-hidden', 'false');
            $('.menu-toggleable-left').removeClass('in');

            $mainMenu.find('.container:first').addClass('d-none');
            clearSelection($('.nav-item.dropdown.show').find('.back button')[0]);
            $('.modal-background').css('z-index', '');
            $('.modal-background').css('display', 'none');
            setFocus($('.navbar-toggler-js'), 600);
        } else {
            // Opening Menu
            $('body').addClass('fixed');
            $('.navbar-toggler-js').addClass('navbar-toggler--is-active');
            $mainMenu.find('.container:first').removeClass('d-none');
            $mainMenu.toggleClass('in');

            $('header').siblings().attr('aria-hidden', 'true');
            $mainMenu.removeClass('d-none');
            $mainMenu.attr('aria-hidden', 'false');
            $mainMenu.siblings().attr('aria-hidden', 'true');

            setFocus($('.main-menu .nav.navbar-nav .nav-link').first(), 600);
            $('.modal-background').css('z-index', '998');
            $('.modal-background').css('display', 'block'); // Avoid calling .show(), that one performs an expensive getComputedStyle() call.
        }
    });

    keyboardAccessibility('.navbar-header .user',
        {
            40: function ($popover) { // down
                if ($popover.children('a').first().is(':focus')) {
                    $popover.next().children().first().focus();
                } else {
                    $popover.children('a').first().focus();
                }
            },
            38: function ($popover) { // up
                if ($popover.children('a').first().is(':focus')) {
                    $(this).focus();
                    $popover.removeClass('show');
                } else {
                    $popover.children('a').first().focus();
                }
            },
            27: function () { // escape
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            },
            9: function () { // tab
                $('.navbar-header .user .popover').removeClass('show');
                $('.user').attr('aria-expanded', 'false');
            }
        },
        function () {
            return $('.user .popover li.nav-item');
        });

    $('.navbar-header .user').on('mouseenter focusin', function () {
        if ($('.navbar-header .user .popover').length > 0) {
            $('.navbar-header .user .popover').addClass('show');
            $('.user').attr('aria-expanded', 'true');
        }
    });

    $('.navbar-header .user').on('mouseleave', function () {
        $('.navbar-header .user .popover').removeClass('show');
        $('.user').attr('aria-expanded', 'false');
    });

    $window.on('resize', function () {
        stickyHeader();
    });

    // custom changes for hide and show search in mobile
    var $mobileSearch = $('.search-mobile');
    var $stickyIcon = $('.sticky-search');
    var addToCartPos;
    var scrollPos;
    var action = $('.page').data('action');
    var isHeaderSearchHiddenPage = action && (action.indexOf('Login') === 0 || action.indexOf('Account') === 0 || action.indexOf('Cart') === 0 || action.indexOf('ShoeFinder') === 0);
    var hideSearchBarOnMobile = function () {
        if ($window.width() < 769) {
            $mobileSearch.removeClass('show').addClass('hide');
            $stickyIcon.removeClass('hide').addClass('show');
        }
    };
    if (isHeaderSearchHiddenPage) {
        hideSearchBarOnMobile();
    }

    // add sticky add to cart when the page is scrolled down
    var $stickyBtn = $('.add-to-cart-sticky');
    var $headerNav = $('#header:not(.no-hide) .header-navigation');
    $window.on('scroll', function () {
        var $pageBtn = $('.cart-and-ipay .add-to-cart').not('.d-none');
        scrollPos = $window.scrollTop();
        if ($window.width() < 992 && !isHeaderSearchHiddenPage && !$mobileSearch.hasClass('search-active')) {
            if (scrollPos > 0) {
                $mobileSearch.removeClass('show').addClass('hide');
                $stickyIcon.removeClass('hide').addClass('show');
            } else {
                $mobileSearch.removeClass('hide').addClass('show');
                $stickyIcon.removeClass('show').addClass('hide');
            }
        }
        if (isHeaderSearchHiddenPage && !$('.search-mobile').hasClass('hide')) {
            $mobileSearch.addClass('hide');
            $('.sticky-search').removeClass('hide').addClass('show');
        }
        if ($pageBtn.length > 0) {
            addToCartPos = $('.add-to-cart').offset().top;
            headerHeight = $('.header-fixed').outerHeight();
            var btnCon = (scrollPos) - ($('.cart-and-ipay .add-to-cart').outerHeight());
            debounce(function () {
                if (btnCon > addToCartPos) {
                    $stickyBtn.addClass('active');
                    $headerNav.addClass('hide-navigation');
                } else {
                    $stickyBtn.removeClass('active');
                    $headerNav.removeClass('hide-navigation');
                }
            }, 400)();
        }
        debounce(function () {
            stickyHeader();
        }, 400)();
    });

    // Dynamic header min-width on hiding/showing promotional message.
    $('#Promo_bar_details_Container').on('hidden.bs.collapse shown.bs.collapse', function () {
        stickyHeader();
    });

    // custom changes to display the search bar in mobile on click of search icon
    $('.sticky-search').on('click', function () {
        $('.search-mobile').removeClass('hide');
        $('.sticky-search').removeClass('show').addClass('hide');
    });

    updateFlyoutClose();

    $('.navbar-nav .dropdown > a').on('click', function () {
        updateFlyoutClose();
    });

    $(document).on('click', '.js-logoutButton', function () {
        sessionStorage.removeItem('accountOrders');
    });
};
