'use strict';

const debounce = require('../util/debounce');
const measures = require('../util/measures');

var endpoint = $('.suggestions-wrapper').data('url');
var minChars = 0;
var UP_KEY = 38;
var DOWN_KEY = 40;
var DIRECTION_DOWN = 1;
var DIRECTION_UP = -1;
var ajaxProgress;

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
function getSuggestionsWrapper(scope) {
    return $(scope).closest('.input-wrapper').siblings('.suggestions-wrapper');
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Remove modal classes needed for mobile suggestions
 *
 */
function clearModals() {
    $('body').removeClass('modal-open');
    $('header').siblings().attr('aria-hidden', 'false');
    $('.suggestions').removeClass('modal');
}

/**
 * Apply modal classes needed for mobile suggestions
 *
 * @param {Object} scope - Search input field DOM element
 */
function applyModals(scope) {
    if (isMobileSearch(scope)) {
        $('body').addClass('modal-open');
        $('header').siblings().attr('aria-hidden', 'true');
        getSuggestionsWrapper(scope).find('.suggestions').addClass('nb-modal');
    }
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('');
    clearModals();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Toggle search field icon from search to close and vice-versa
 *
 * @param {string} action - Action to toggle to
 */
function toggleSuggestionsIcon(action) {
    var mobileSearchIcon = '.search-mobile button.';
    var iconSearch = 'fa-search';
    var iconSearchClose = 'fa-close';

    if (action === 'close') {
        $(mobileSearchIcon + iconSearch).removeClass(iconSearch).addClass(iconSearchClose).attr('type', 'button');
    } else {
        $(mobileSearchIcon + iconSearchClose).removeClass(iconSearchClose).addClass(iconSearch).attr('type', 'submit');
    }
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Positions Suggestions panel on page
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function positionSuggestions(scope) {
    var outerHeight;
    var $scope;
    var $suggestions;
    var top;

    if (isMobileSearch(scope)) {
        $scope = $(scope);
        top = $scope.offset().top;
        outerHeight = $scope.outerHeight();
        $suggestions = getSuggestionsWrapper(scope).find('.suggestions');
        $suggestions.css('top', top + outerHeight);

        handleMoreContentBelowIcon(scope);

        // Unfortunately, we have to bind this dynamically, as the live scroll event was not
        // properly detecting dynamic suggestions element's scroll event
        $suggestions.scroll(function () {
            handleMoreContentBelowIcon(this);
        });
    }
}

/**
 * Searach suggestion wrapper in small desktop
 *
 * @param {Object|string} scope - DOM element, usually the input.search-field element
 */

function smallDesktop() { // eslint-disable-line require-jsdoc
    var isSmallDesktop = measures.isSmallDesktop();
    if (isSmallDesktop) {
        $('.suggestions').addClass('small-desktop');
    } else {
        $('.suggestions').removeClass('small-desktop');
    }
}

/**
 * Searach custom changes
 *
 * @param {Object|string} scope - DOM element, usually the input.search-field element
 */
function searchsuggetionstyles() {
    var $suggestions = $('.suggestions');
    var $productCustomizerWrapper = $('.product-customizer-wrapper');
    if ($suggestions.is(':visible') === true) {
        smallDesktop();
        if ($('#maincontent .seach-modal-bg').length === 0) {
            $(document).find('#maincontent').prepend('<div class="seach-modal-bg"></div>');
        }

        $suggestions.addClass('four-tile-width');
        if ($productCustomizerWrapper.length) {
            $('.header-fixed').removeClass('position-fixed');
        }
    } else {
        $(document).find('#maincontent .seach-modal-bg').detach();
    }
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    if (!ajaxProgress) {
        var $suggestionsWrapper = getSuggestionsWrapper(this).empty();
        $.spinner().stop();
        var $document = $(document);

        if (typeof response !== 'object') {
            $suggestionsWrapper.append(response).show();
            // $(this).siblings('.reset-button').addClass('d-sm-block');
            $('input.search-field').attr('aria-describedby', 'search-assistive-text')
                .attr('aria-owns', 'search-results')
                .attr('aria-controls', 'search-results');
            positionSuggestions(this);

            if (isMobileSearch(this)) {
                $('body').on('touchstart', function () {
                    $('.search-field').blur();
                });
                toggleSuggestionsIcon('close');
                applyModals(this);
            }
            searchsuggetionstyles();

            $document.find('.search-mobile').addClass('search-active');
            $document.find('.search-mobile').removeClass('hide').addClass('show');
            $document.scrollTop(0);
            $document.find('.sticky-search').removeClass('show').addClass('hide');
            // Trigger screen reader by setting aria-describedby with the new suggestion message.
            var suggestionsList = $('.suggestions .item');
            if (suggestionsList.length) {
                $('input.search-field').attr('aria-describedby', 'search-result-count');
            } else {
                $('input.search-field').removeAttr('aria-describedby');
            }
            if (window.suggetionBoxDomReady) {
                window.suggetionBoxDomReady();
            }
        } else {
            $suggestionsWrapper.hide();
            $document.find('#maincontent .seach-modal-bg').detach();
        }
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    var $scope = $(scope);
    if ($scope.val().length >= minChars) {
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($scope.val()),
            method: 'GET',
            success: processResponse,
            error: function () {
            }
        });
    } else {
        toggleSuggestionsIcon('search');
        // $(scope).siblings('.reset-button').removeClass('d-sm-block');
        clearModals();
        getSuggestionsWrapper(scope).empty();
    }
    if ($scope.val().length >= 1) {
        $scope.closest('.input-wrapper').siblings('.reset-button').addClass('d-block');
    } else {
        $scope.closest('.input-wrapper').siblings('.reset-button').removeClass('d-block');
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            var $this = $(this);
            if ($this.hasClass('selected')) {
                $this.removeClass('selected');
                $this.removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $this.removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $this.attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $this.attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

module.exports = function () {
    $('form[name="simpleSearch"]').submit(function (e) {
        var searchValue = '';
        // If the viewport matches the media query below use the mobile search input
        // 991px is the max width for the mobile search input form
        const isMobile = measures.isMobile();

        if (isMobile) {
            searchValue = $('.search-mobile form[name="simpleSearch"] :input[name="q"]').val();
        } else {
            searchValue = $('.search form[name="simpleSearch"] :input[name="q"]').val();
        }

        if (!searchValue) {
            e.preventDefault();
        }

        if (!ajaxProgress) {
            var isMobileTextboxEmpty = !!(typeof ($('.search-mobile .search-field').val()) !== 'undefined' && $('.search-mobile .search-field').val().length === 0);
            var isDesktopTextboxEmpty = !!(typeof ($('.navbar-header .search-field').val()) !== 'undefined' && $('.navbar-header .search-field').val().length === 0);
            if (isMobileTextboxEmpty || isDesktopTextboxEmpty) {
                ajaxProgress = false;
            } else {
                ajaxProgress = true;
            }
        }
        var suggestionsList = $('.suggestions .item').filter('.selected');
        if (suggestionsList.length !== 0) {
            e.preventDefault();
            suggestionsList.find('a')[0].click();
        }
    });

    $('input.search-field').each(function () {
        getSuggestionsWrapper(this).show();
        const $header = $('#header');
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            ajaxProgress = false;
            $header.addClass('open-search');
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });
    });
    $('button.search-button').on('click', function () {
        if ($(this).closest('form').find('input.search-field').length > 0 && $(this).closest('form').find('input.search-field').val().length === 0) {
            $('input.search-field').focus();
        }
    });
    $('body').on('click focusout', function (e) {
        var $searchContainer = $('.search');
        var $suggestions = $('.suggestions');
        if (!isMobileSearch(e.target) && ($suggestions.find('a, button').last()[0] === e.target || !$searchContainer.has(e.target).length)) {
            $suggestions.hide();
            $(document).find('#maincontent .seach-modal-bg').detach();
        }
    });

    $('body').on('click touchend', '.search-mobile button.fa-close', function (e) {
        e.preventDefault();
        $('.suggestions').hide();
        toggleSuggestionsIcon('search');
        tearDownSuggestions();
    });

    $('.site-search .reset-button').on('click', function () {
        var $this = $(this);
        $this.removeClass('d-block');
        $('input.search-field').val('');
        $('input.search-field').attr('value', '');
        setTimeout(function () {
            $this.closest('form').find('.search-field').focus();
        }, 500);
    });

    $(document).on('click', '.suggestions .close', function () {
        ajaxProgress = true;
        $('#header').removeClass('open-search');
        clearModals();
        $('.suggestions').hide();
        $(document).find('#maincontent .seach-modal-bg').detach();
        $('.search-mobile').removeClass('search-active');
    });
    $(window).resize(function () {
        smallDesktop();
    });
};
