/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable strict */

/**
 * Helper function unslick carousel
 * @param {Element} $carousel - element on which carousel is to be initalized
 */
function unslickCarousel($carousel) {
    if (!$carousel) {
        return;
    }
    $carousel.removeClass('invisible');
    if ($carousel.hasClass('slick-initialized')) {
        $carousel.slick('unslick');
    }
}

/**
 * This function enhances the accessibility of a carousel by setting the proper ARIA attributes
 * @param {Element} $carouselSelector - The element that contains the carousel.
 */
function enhanceCarouselAccessibility($carouselSelector) {
    var $slides = $carouselSelector.find('.slick-slide');
    $carouselSelector.on('afterChange', function () {
        var $activeSlides = $carouselSelector.find('.slick-slide.slick-active');
        var horizontalCarouselDirection = $carouselSelector.attr('data-direction');
        $slides.not('.slick-active').attr('aria-hidden', 'true');
        var newSlideIn = horizontalCarouselDirection === 'left' ? $activeSlides.last() : $activeSlides.first();
        newSlideIn.attr('aria-hidden', 'false').find('a').first().focus();
    });
    $carouselSelector.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $carouselSelector.attr('data-direction', currentSlide < nextSlide ? 'left' : 'right');
    });
}

/**
 * Method to handle slide change event
 * @param {*} $carousel placeholder for the carousel element
 * @param {*} event placeholder for the current event
 * @param {*} slick placeholder for the slick element
 * @param {*} currentSlide placeholder for the currentSlide
 */
function handleEinsteinCarouselArrows($carousel, event, slick, currentSlide) {
    var visibleTiles = $carousel.find('.slick-slide:visible').length;
    var hideNextArrow = (visibleTiles - currentSlide) < 4;
    if (hideNextArrow) {
        $carousel.find('.next-arrow').addClass('d-none');
    } else {
        $carousel.find('.next-arrow').removeClass('d-none');
    }
}
/**
 * Method to handle einstein carousel arrow elements
 * @param {Element} $carouselSelector - The element that contains the carousel.
 */
function handleSlideChange($carouselSelector) {
    if ($carouselSelector && $carouselSelector.hasClass('recs-filter-js')) {
        $carouselSelector.on('afterChange', function (event, slick, currentSlide) {
            handleEinsteinCarouselArrows($(this), event, slick, currentSlide);
        });
    }
}

/**
 * Helper function to initalize carousel
 * @param {Element} $carousel - element on which carousel is to be initalized
 */
function initCarousel($carousel) {
    var $window = $(window);
    var totalCarouselItems = parseFloat($carousel.data('items-count'));
    var slidesToShow = parseFloat($carousel.data('slidesToShow')) || 3.6;
    $carousel.find('script').remove();
    if ($window.width() < 992 && !$carousel.hasClass('rotating-header-banner')) {
        unslickCarousel($carousel);
    } else if (totalCarouselItems > slidesToShow) {
        $carousel.not('.slick-initialized').on('init', function () {
            $carousel.removeClass('invisible');
        });
        enhanceCarouselAccessibility($carousel);
        handleSlideChange($carousel);
        var scrollBarSelector = '#slick-scrollbar-tabs-' + $carousel.attr('id');
        import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js').then(function () {
            $carousel.not('.slick-initialized').slick({
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
                infinite: false,
                prevArrow: '<button class="slide-arrow prev-arrow" aria-label="previous"></button>',
                nextArrow: '<button class="slide-arrow next-arrow" aria-label="next"></button>',
                dots: true,
                appendDots: scrollBarSelector
            });
        });
    } else {
        $carousel.removeClass('invisible');
    }
}

/**
 * Helper function to initalize carousel
 * @param {Element} carousel - element on which carousel is to be initalized
 */
function initializeMobileCarousel(carousel) {
    var $window = $(window);
    var $mobileCarousel = $(carousel);
    var totalCarouselItems = parseFloat($mobileCarousel.data('itemsCount'));
    var slidesToShow = parseFloat($mobileCarousel.data('slidesToShow')) || 2.1;
    var showDots = $mobileCarousel.data('showDots') === true || false;
    if ($window.width() > 992) {
        unslickCarousel($mobileCarousel);
    } else if (totalCarouselItems > 2) {
        $mobileCarousel.not('.slick-initialized').on('init', function () {
            $mobileCarousel.removeClass('invisible');
        });
        enhanceCarouselAccessibility($mobileCarousel);
        import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js').then(function () {
            $mobileCarousel.not('.slick-initialized').slick({
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
                infinite: false,
                prevArrow: false,
                nextArrow: false,
                cssEase: 'ease-in-out',
                touchThreshold: 100,
                mobileFirst: true,
                swipeToSlide: true,
                dots: showDots,
                responsive: [
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1.1
                        }
                    }
                ]
            });
        });
    } else {
        $mobileCarousel.removeClass('invisible');
    }
}

/**
 * This method removes any slides from the product carousels this method also
 * leverages data-filtered-recommendations data from the DOM to determine which tiles to hide
 */
function removeUnvailableProductTiles() {
    var einsteinMethods = require('./einsteinCarousel');
    var $horizontalCarousel = $('.product-carousel');

    if (($horizontalCarousel.length && $horizontalCarousel.hasClass('slick-initialized')) || ($horizontalCarousel.length < 4)) {
        for (let i = 0; i < $horizontalCarousel.length; i += 1) {
            var carousel = $horizontalCarousel[i];
            var filteredDataAttr = $(carousel).attr('data-filtered-recommendations');
            var hasFilteredDataAttr = filteredDataAttr && filteredDataAttr !== undefined && filteredDataAttr !== 'null';
            if (hasFilteredDataAttr) {
                var $currentCarousel = $(carousel);
                if ($currentCarousel && $currentCarousel.hasClass('slick-initialized')) {
                    $currentCarousel.slick('slickUnfilter');
                    var productTileCtn = $currentCarousel.find('.image-container .product');
                    var recommendationData = JSON.parse(filteredDataAttr);
                    einsteinMethods.hideUnavailableProducts(productTileCtn, recommendationData);
                    $currentCarousel.slick('slickFilter', '.show-tile');
                }
            }
        }
    }
}

/**
 * Initializes desktop carousel
 */
function initializeDesktopCarousel() {
    var $carousels = $('.category-carousel');
    $carousels.each(function (j) {
        initCarousel($($carousels[j]));
    });

    var $productCarousels = $('.tab-layout .product-carousel');
    $productCarousels.each(function (i) {
        var $productCarousel = $($productCarousels[i]);
        initCarousel($productCarousel);
    });
    setTimeout(removeUnvailableProductTiles, 10);
}

/**
 * Helper function to initalize mobile carousel
 */
function initMobileCarousel() {
    var $carousels = $('.carousel-mobile, .visual-navigation-carousel');
    $carousels.each(function (index, carousel) {
        initializeMobileCarousel(carousel);
    });
}

/**
 * This method handles the tab clicks for the product carousels in tab layouts
 */
function initTabs() {
    var slickLib = import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js');
    var $tabLayout = $('.tab-layout:not([data-scripted])');

    $tabLayout.each(function (i) {
        var $element = $($tabLayout[i]);
        $element.attr('data-scripted', 'true');
        var $tabHeadings = $element.find('.tab-item');
        var $navLinks = $element.find('.nav-link');
        var $tabPanes = $element.find('.tab-pane');

        $navLinks.each(function (index) {
            var controls = 'controls' + $element.attr('id') + '-' + index;

            if ($tabPanes.length && $tabPanes[index]) {
                $tabPanes[index].setAttribute('id', controls);
                $navLinks[index].setAttribute('aria-controls', controls);
                $navLinks[index].setAttribute('href', '#' + controls);

                var tabId = $navLinks[index].getAttribute('id');
                $tabPanes[index].setAttribute('aria-labelledby', tabId);

                $navLinks[index].innerHTML = $tabHeadings[index].getAttribute('data-heading');

                var tab = $($tabPanes[index]);

                $element.find('.tab-content').append(tab);
                $($navLinks[index]).on('shown.bs.tab', function () {
                    var clickedCarousel = 'div#' + controls;
                    var slickScrollbarId = $(clickedCarousel).find('.product-carousel').attr('id') || $(clickedCarousel).find('.einstein-carousel').attr('id');
                    var scrollBarSelector = '#slick-scrollbar-tabs-' + slickScrollbarId;

                    slickLib.then(function () {
                        var carouselSelector = 'div#' + slickScrollbarId + '.product-carousel.slick-initialized';
                        $(carouselSelector).slick('unslick').slick({
                            slidesToShow: 3.6,
                            slidesToScroll: 1,
                            infinite: false,
                            prevArrow: '<button class="slide-arrow prev-arrow" aria-label="previous"></button>',
                            nextArrow: '<button class="slide-arrow next-arrow" aria-label="next"></button>',
                            dots: true,
                            appendDots: scrollBarSelector
                        });
                    });
                });
                // Show first tab on load
                if (index === 0) {
                    tab.addClass('show active');
                }
            }
        });
    });
}

/**
 * Initialize initializeShopTheLookCarousel
 * @param {Object} e event object
 */
function initializeShopTheLookCarousel(e) {
    var $target = $(e.target);
    var $carouselContainer = $('.shop-look-carousel');
    var $navButtons = $carouselContainer.find('.nav-button');
    var $slideContent = $carouselContainer.find('.slide-content');
    var isPreviousBtn = $target.hasClass('previous');
    $slideContent.removeClass('show-previous show-next');
    $navButtons.removeClass('active');
    $target.addClass('active');
    $slideContent.removeAttr('style');
    if (!isPreviousBtn) {
        $carouselContainer.animate({ scrollLeft: $carouselContainer[0].scrollWidth - $carouselContainer.outerWidth() }, 300);
    } else {
        $slideContent.addClass('show-previous');
        $carouselContainer.animate({ scrollLeft: 0 }, 300);
    }
}

/**
 * Initialize product Grid Width
 */
function initializeProductGridWidth() {
    var $productGridContainer = $('.shop-the-look .products-grid-container');
    if ($productGridContainer.length) {
        var noOfProductTiles = $productGridContainer.find('.odd-indexed-products').children().length;
        var gridWidth = (noOfProductTiles * 17) + 2; // To include extra spacings
        $productGridContainer.css('min-width', gridWidth + 'rem');
    }
}

/**
 *  Show Product Grid
 */
function showProductGrid() {
    var $shopLookContainer = $('.shop-the-look');
    var $productGrid = $shopLookContainer.find('.product-grid');
    if (window.utag_data) {
        $(document).trigger('shop-the-look:showcollection');
    }
    if ($productGrid.length) {
        $productGrid.removeClass('d-none');
    }
}

/**
 * Initialize initializeShopTheLookCarousel
 */
function addShopTheLookEventListeners() {
    $(document).on('click', '.shop-look-carousel button.nav-button', initializeShopTheLookCarousel);
    $(document).on('click', '.show-product-grid-btn', showProductGrid);
}

/**
 * Helper function to initalize carousel
 * @param {Element} $carousel - element on which carousel is to be initalized
 */
function carouselGBFour($carousel) {
    if ($carousel.data('items-count') > 4) {
        import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js').then(function () {
            if ($carousel.hasClass('slick-initialized')) {
                $carousel.slick('unslick');
            }
            $carousel.slick({
                slidesToShow: 4.156,
                slidesToScroll: 1,
                infinite: false,
                prevArrow: '<button class="slide-arrow prev-arrow" aria-label="previous"></button>',
                nextArrow: '<button class="slide-arrow next-arrow" aria-label="next"></button>'
            });
        });
    } else {
        unslickCarousel($carousel);
    }
}

/**
 * Helper function to initalize carousel
 * @param {Element} $carousel - element on which carousel is to be initalized
 */
function carouselGBSlickTwo($carousel) {
    if ($carousel.data('items-count') > 2) {
        if ($carousel.hasClass('slick-initialized')) {
            $carousel.slick('unslick');
        }
        import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js').then(function () {
            $carousel.slick({
                slidesToShow: 2.265,
                slidesToScroll: 1,
                infinite: false,
                arrows: false,
                dots: true
            });
        });
    } else {
        unslickCarousel($carousel);
    }
}

/**
 * Initialize initializeGuidedBuyingCarousel
 */
function initializeGuidedBuyingCarousel() {
    var $horizontalCarousels = $('.guided-buying');
    var $window = $(window);

    if ($horizontalCarousels.length) {
        $horizontalCarousels.each(function (i) {
            var $horizontalCarousel = $($horizontalCarousels[i]);

            $horizontalCarousel.find('script').remove();
            enhanceCarouselAccessibility($horizontalCarousel);
            handleSlideChange($horizontalCarousel);
            if ($window.width() >= 992) {
                carouselGBFour($horizontalCarousel);
            } else {
                carouselGBSlickTwo($horizontalCarousel);
            }
        });
    }
}

/**
 * This method handles initializing the horizontal product carousel component for desktop
 * This applies to non-tab layout carousels
 * @param {JQuery} $horizontalCarousel - The element that contains the carousel.
 */
function initDesktopHoriCarousel($horizontalCarousel) {
    var slickLib = import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js');
    var isBisnOpen = $('#bisnProductModal').length > 0 && $('#bisnProductModal').hasClass('show');
    var scrollBarIdString = '#slick-scrollbar-';
    var bisnCarouselSettings = {
        slidesToShow: 1.265,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: true,
        appendDots: scrollBarIdString
    };

    var desktopCarouselSettings = {
        slidesToShow: 3.6,
        slidesToScroll: 1,
        infinite: false,
        prevArrow: '<button class="slide-arrow prev-arrow" aria-label="previous"></button>',
        nextArrow: '<button class="slide-arrow next-arrow" aria-label="next"></button>',
        dots: true,
        appendDots: scrollBarIdString
    };

    if (isBisnOpen && $horizontalCarousel.closest('#product-recommender-bisn').length > 0 && $horizontalCarousel.data('items-count') > 1) {
        $horizontalCarousel.removeClass('row').find('p-tile').removeClass('col-4');

        slickLib.then(function () {
            if ($horizontalCarousel.hasClass('slick-initialized')) {
                $horizontalCarousel.slick('unslick');
            }
            var slickScrollbarId = $horizontalCarousel.attr('id') || $horizontalCarousel.attr('data-id');
            var scrollBarSelector = scrollBarIdString + slickScrollbarId;
            // Append scrollbar id
            bisnCarouselSettings.appendDots = scrollBarSelector;
            $horizontalCarousel.slick(bisnCarouselSettings);
        }).catch(function (e) {
            window.console.log(e);
        });
    } else if ($horizontalCarousel.data('items-count') > 0) {
        $horizontalCarousel.removeClass('row').find('p-tile').removeClass('col-4');

        slickLib.then(function () {
            if ($horizontalCarousel.hasClass('slick-initialized')) {
                $horizontalCarousel.slick('unslick');
            }
            var slickScrollbarId = $horizontalCarousel.attr('id') || $horizontalCarousel.attr('data-id');
            var scrollBarSelector = scrollBarIdString + slickScrollbarId;
            // Append scrollbar id
            desktopCarouselSettings.appendDots = scrollBarSelector;
            $horizontalCarousel.slick(desktopCarouselSettings);
        }).catch(function (e) {
            window.console.log(e);
        });
    } else {
        unslickCarousel($horizontalCarousel);
        $horizontalCarousel.addClass('row');
        $horizontalCarousel.find('p-tile').addClass('col-4');
    }
}

/**
 * This method handles initializing the horizontal product carousel component for mobile
 * This applies to non-tab layout carousels
 * @param {JQuery} $horizontalCarousel - The element that contains the carousel.
 */
function initMobileHoriCarousel($horizontalCarousel) {
    var slickLib = import(/* webpackChunkName: "slick" */ '@accessible360/accessible-slick/slick/slick.min.js');

    $horizontalCarousel.removeClass('row').find('p-tile').removeClass('col-4');
    if ($horizontalCarousel.hasClass('slick-initialized')) {
        $horizontalCarousel.slick('unslick');
    }

    var slickScrollbarId = $horizontalCarousel.attr('id') || $horizontalCarousel.attr('data-id');
    var scrollBarSelector = '#slick-scrollbar-' + slickScrollbarId;
    var mobileCarouselSettings = {
        slidesToShow: 2.1,
        slidesToScroll: 1,
        infinite: false,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1.1
                }
            }
        ]
    };

    if (scrollBarSelector && document.querySelector(scrollBarSelector)) {
        mobileCarouselSettings.appendDots = scrollBarSelector;
    }

    slickLib.then(function () {
        $horizontalCarousel.slick(mobileCarouselSettings);
    }).catch(function (e) {
        window.console.log(e);
    });
}

/**
 * Product carousel content component initalization for horizontal carousel not in tab layout
*/
function initializeHorizontalPdtCarousel() {
    var $horizontalCarousels = $('.horizontal-product-carousel');
    var $window = $(window);

    if ($horizontalCarousels.length) {
        $horizontalCarousels.each(function (i) {
            var $horizontalCarousel = $($horizontalCarousels[i]);

            $horizontalCarousel.find('script').remove();
            $horizontalCarousel.on('init', function () {
                $horizontalCarousel.removeClass('invisible');
            });

            enhanceCarouselAccessibility($horizontalCarousel);
            handleSlideChange($horizontalCarousel);

            if ($window.width() >= 992) {
                // Desktop
                initDesktopHoriCarousel($horizontalCarousel);
            } else if ($horizontalCarousel.data('items-count') > 1) {
                // Mobile
                initMobileHoriCarousel($horizontalCarousel);
            } else {
                unslickCarousel($horizontalCarousel);
                $horizontalCarousel.addClass('row');
                $horizontalCarousel.find('p-tile').removeClass('col-4').addClass('col-12');
            }
        });
        setTimeout(removeUnvailableProductTiles, 10);
    }
}

$(window).on('resize', function () {
    var currentWidth = $(window).width();
    if (window.currentWidth !== currentWidth) {
        window.currentWidth = currentWidth;
        initializeDesktopCarousel();
        initMobileCarousel();
        initializeHorizontalPdtCarousel();
        initializeGuidedBuyingCarousel();
    }
});

$(document).ready(function () {
    window.currentWidth = $(window).width();
    initializeDesktopCarousel();
    initializeHorizontalPdtCarousel();
    initMobileCarousel();
    initTabs();
    addShopTheLookEventListeners(); // No need to call on content:updated.
    initializeProductGridWidth();
    initializeGuidedBuyingCarousel(); // No need to call on content:updated.
    $('body').on('einstien-carousel:setup', function () {
        initializeHorizontalPdtCarousel();
        initializeDesktopCarousel();
    });

    $('body').on('bisn-carousel:setup', function () {
        initializeHorizontalPdtCarousel();
    });

    document.addEventListener('content:updated', () => {
        initializeDesktopCarousel();
        initializeHorizontalPdtCarousel();
        initMobileCarousel();
        initTabs();
        initializeProductGridWidth();
    });

    // Observe product detaails
    var pdtRecommendations = $('#pdt-recommendations, .homepage');

    if (pdtRecommendations.length) {
        var mutationHandler = function (mutationList) {
            var keys = Object.keys(mutationList);
            keys.forEach(function (val) {
                var mutation = mutationList[val];

                if (mutation.type === 'childList') {
                    var addedNodes = mutation && mutation.addedNodes;
                    if (addedNodes.length && $(addedNodes).find('.horizontal-product-carousel').length) {
                        initializeHorizontalPdtCarousel();
                    }
                }
            });
        };
        var config = { attributes: true, childList: true, subtree: true };
        var observer = new MutationObserver(mutationHandler);
        observer.observe(pdtRecommendations[0], config);
    }
});

module.exports = {
    removeUnvailableProductTiles: removeUnvailableProductTiles
};
